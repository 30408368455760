import { Col, Image, Row, Typography } from 'antd';

import logo from '../../assets/images/logo.svg';
import { appConfig } from '../../config/appConfig';

export const TermsOfUsePage = () => {
  return (
    <Row justify="center" style={{ marginTop: 32, marginBottom: 32 }}>
      <Col xs={24} sm={24} md={24} lg={16}>
        <a href={appConfig.websiteUrl} target="_blank" rel="noopener noreferrer">
          <Image width={200} src={logo} preview={false} />
        </a>
        <Typography.Title style={{ fontWeight: 'bold' }}>Terms of Use</Typography.Title>
        <Typography.Title level={4}>1. Acceptance of Terms</Typography.Title>
        <Typography.Text>
          By accessing and using EnzRossi Connect, you agree to comply with and be bound by these
          Terms of Use. If you do not agree to these terms, please do not use the platform.
        </Typography.Text>
        <Typography.Title level={4}>2. Use of the Platform</Typography.Title>
        <Typography.Text>
          You agree to use EnzRossi Connect for lawful purposes only. You are prohibited from: Using
          the platform for any illegal activities Attempting to gain unauthorized access to our
          systems Disrupting or interfering with the platform’s functionality
        </Typography.Text>
        <Typography.Title level={4}>3. Account Registration</Typography.Title>
        <Typography.Text>
          To use certain features of EnzRossi Connect, you may need to create an account. You agree
          to provide accurate and complete information and to keep your account information
          up-to-date.
        </Typography.Text>
        <Typography.Title level={4}>4. User Content</Typography.Title>
        <Typography.Text>
          You are responsible for any content you post on EnzRossi Connect. You agree not to post
          content that is: Offensive, harmful, or inappropriate Infringing on intellectual property
          rights Misleading or fraudulent
        </Typography.Text>
        <Typography.Title level={4}>5. Intellectual Property</Typography.Title>
        <Typography.Text>
          All content on EnzRossi Connect, including text, graphics, logos, and software, is the
          property of EnzRossi or its licensors and is protected by intellectual property laws.
        </Typography.Text>
        <Typography.Title level={4}>6. Termination</Typography.Title>
        <Typography.Text>
          We reserve the right to terminate or suspend your account at any time for any reason,
          including violations of these Terms of Use.
        </Typography.Text>
        <Typography.Title level={4}>7. Limitation of Liability</Typography.Title>
        <Typography.Text>
          EnzRossi Connect is provided “as is” without warranties of any kind. We do not guarantee
          the accuracy or completeness of the platform’s content. To the extent permitted by law,
          EnzRossi shall not be liable for any damages arising from the use of the platform.
        </Typography.Text>
        <Typography.Title level={4}>8. Changes to These Terms</Typography.Title>
        <Typography.Text>
          We may update these Terms of Use from time to time. We will notify you of any changes by
          posting the new terms on our platform.
        </Typography.Text>
        <Typography.Title level={4}>9. Governing Law</Typography.Title>
        <Typography.Text>
          These Terms of Use are governed by and construed in accordance with the laws of Brazil.
        </Typography.Text>
        <Typography.Title level={4}>10. Contact Us</Typography.Title>
        <Typography.Text>
          If you have any questions about these Terms of Use, please contact us at{' '}
          {appConfig.contactEmail}.
        </Typography.Text>
      </Col>
    </Row>
  );
};
