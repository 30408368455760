import { CheckOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Space, message } from 'antd';
import { useState } from 'react';

import { companyRolesOptions } from './options';
import { useAuth } from '../../../providers/AuthProvider';
import { UserData } from '../../../types/UserData';

interface SecondCompanyStepValues extends Pick<UserData, 'companyProject' | 'companyRoles'> {}

interface SecondCompanyStepProps {
  onFinishStep: () => void;
  onGoBack: () => void;
}

export const SecondCompanyStep = ({ onFinishStep, onGoBack }: SecondCompanyStepProps) => {
  const { userData, updateUserData } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: SecondCompanyStepValues) => {
    try {
      setLoading(true);
      const companyRoles = values.companyRoles as unknown as string[];
      await updateUserData({
        companyProject: values.companyProject,
        companyRoles: companyRoles.join('|'),
      });
      onFinishStep();
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message || 'Something went wrong, please try again.',
      });
    }
  };

  return (
    <Form
      style={{ marginTop: 32 }}
      requiredMark={false}
      layout="vertical"
      size="large"
      initialValues={{
        companyProject: userData?.companyProject,
        companyRoles: userData?.companyRoles?.split('|'),
      }}
      onFinish={handleSubmit}>
      {contextHolder}
      <Form.Item
        style={{ marginBottom: 12 }}
        name="companyRoles"
        label="What are the roles you're looking for?"
        rules={[{ required: true, message: '' }]}>
        <Select
          allowClear
          disabled={loading}
          style={{ width: '100%' }}
          placeholder="Please select all that apply"
          mode="multiple"
          options={companyRolesOptions}
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 12 }}
        name="companyProject"
        label="Tell us about your project and how we can help you"
        rules={[{ required: true, message: '' }]}>
        <Input.TextArea rows={8} disabled={loading} placeholder="Please describe your project" />
      </Form.Item>

      <div style={{ textAlign: 'center' }}>
        <Space>
          <Button type="default" onClick={onGoBack}>
            <LeftOutlined />
            Back
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            Done
            <CheckOutlined />
          </Button>
        </Space>
      </div>
    </Form>
  );
};
