import { Button, Divider, Flex, Image, Result, Typography, message } from 'antd';
import { useState } from 'react';

import icon from '../../../assets/images/icon.svg';
import { useAuth } from '../../../providers/AuthProvider';

export const CompletedHeader = () => {
  const { updateUserData } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  const handleGoToApp = async () => {
    setLoading(true);
    try {
      await updateUserData({
        onboarded: true,
      });
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message || 'Something went wrong, please try again.',
      });
    }
  };

  return (
    <Flex justify="center" align="center" style={{ marginTop: 32, marginBottom: 32 }}>
      {contextHolder}

      <Result
        style={{ padding: '32px 0px' }}
        icon={<Image width={60} src={icon} preview={false} />}
        title={
          <>
            <Divider />
            <Typography.Title level={3} style={{ fontWeight: 'bold' }}>
              Welcome to EnzRossi Connect – You're all set! 🚀
            </Typography.Title>
            <Divider />
          </>
        }
        subTitle={
          <Typography.Text>
            Thank you for sharing your details with us! You've successfully completed the setup.
          </Typography.Text>
        }
        extra={[
          <Button
            key="openApp"
            loading={loading}
            type="primary"
            size="large"
            onClick={handleGoToApp}>
            Go to the platform
          </Button>,
        ]}
      />
    </Flex>
  );
};
