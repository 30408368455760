import { Col, Image, Row, Typography } from 'antd';

import logo from '../../assets/images/logo.svg';
import { appConfig } from '../../config/appConfig';

export const PrivacyPage = () => {
  return (
    <Row justify="center" style={{ marginTop: 32, marginBottom: 32 }}>
      <Col xs={24} sm={24} md={24} lg={16}>
        <a href={appConfig.websiteUrl} target="_blank" rel="noopener noreferrer">
          <Image width={200} src={logo} preview={false} />
        </a>

        <Typography.Title style={{ fontWeight: 'bold' }}>Privacy Policy</Typography.Title>

        <Typography.Title level={4}>1. Introduction</Typography.Title>

        <Typography.Text>
          Welcome to EnzRossi Connect! We value your privacy and are committed to protecting your
          personal information. This Privacy Policy outlines how we collect, use, and safeguard your
          data when you use our platform.
        </Typography.Text>

        <Typography.Title level={4}>2. Information We Collect</Typography.Title>

        <Typography.Text>
          We may collect the following types of information: Personal Information: Name, email
          address, phone number, and other contact details. Usage Data: Information about how you
          use the platform, including IP address, browser type, pages visited, and time spent on
          each page. Cookies and Tracking Technologies: We use cookies to enhance your experience
          and gather usage data.
        </Typography.Text>
        <Typography.Title level={4}>3. How We Use Your Information</Typography.Title>

        <Typography.Text>
          We use your information to: Provide and improve our services Communicate with you
          Personalize your experience Analyze usage patterns Ensure the security of our platform
        </Typography.Text>
        <Typography.Title level={4}>4. Sharing Your Information</Typography.Title>
        <Typography.Text>
          We do not sell or rent your personal information. We may share your information with:
          Service Providers: Third-party vendors who assist us in operating the platform. Legal
          Requirements: If required by law or to protect our rights.
        </Typography.Text>
        <Typography.Title level={4}>5. Security</Typography.Title>
        <Typography.Text>
          We implement appropriate security measures to protect your personal information from
          unauthorized access, disclosure, alteration, or destruction.
        </Typography.Text>

        <Typography.Title level={4}>6. Your Rights</Typography.Title>
        <Typography.Text>
          You have the right to: Access your personal information Correct inaccuracies in your data
          Request the deletion of your information Opt-out of certain data processing activities
        </Typography.Text>
        <Typography.Title level={4}>7. Changes to This Privacy Policy</Typography.Title>
        <Typography.Text>
          We may update this Privacy Policy from time to time. We will notify you of any changes by
          posting the new policy on our platform.
        </Typography.Text>
        <Typography.Title level={4}>8. Contact Us</Typography.Title>
        <Typography.Text>
          If you have any questions or concerns about this Privacy Policy, please contact us at{' '}
          {appConfig.contactEmail}.
        </Typography.Text>
      </Col>
    </Row>
  );
};
