import { Col, Divider, Row, Steps } from 'antd';
import { useState } from 'react';

import { CompletedHeader } from './CompletedHeader/CompletedHeader';
import { FirstCompanyStep } from './FirstCompanyStep/FirstCompanyStep';
import { FirstIndividualStep } from './FirstIndividualStep/FirstIndividualStep';
import { OnboardHeader } from './OnboardHeader/OnboardHeader';
import { SecondCompanyStep } from './SecondCompanyStep/SecondCompanyStep';
import { SecondIndividualStep } from './SecondIndividualStep/SecondIndividualStep';
import { AppLayout } from '../../components/AppLayout/AppLayout';
import { useAuth } from '../../providers/AuthProvider';

export const OnboardingPage = () => {
  const { userData } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const [completed, setCompleted] = useState(false);

  const userType = userData?.type;
  const items = {
    individual: [{ title: 'Tell us about you' }, { title: 'Upload your resume' }],
    company: [{ title: 'Tell us about your company' }, { title: 'Tell us about your project' }],
  };

  return (
    <AppLayout showHeader={false}>
      <Row justify="center">
        <Col md={24} lg={14}>
          {completed ? (
            <CompletedHeader />
          ) : (
            <>
              <OnboardHeader />

              {userType && (
                <>
                  <Divider />
                  <Steps size="small" current={currentStep} items={items[userType]} />
                  <Divider />
                </>
              )}
            </>
          )}

          {userType === 'individual' && (
            <>
              {currentStep === 0 && (
                <FirstIndividualStep onFinishStep={() => setCurrentStep(currentStep + 1)} />
              )}
              {currentStep === 1 && (
                <SecondIndividualStep
                  onGoBack={() => setCurrentStep(currentStep - 1)}
                  onFinishStep={() => {
                    setCurrentStep(currentStep + 1);
                    setCompleted(true);
                  }}
                />
              )}
            </>
          )}

          {userType === 'company' && (
            <>
              {currentStep === 0 && (
                <FirstCompanyStep onFinishStep={() => setCurrentStep(currentStep + 1)} />
              )}
              {currentStep === 1 && (
                <SecondCompanyStep
                  onGoBack={() => setCurrentStep(currentStep - 1)}
                  onFinishStep={() => {
                    setCurrentStep(currentStep + 1);
                    setCompleted(true);
                  }}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </AppLayout>
  );
};
