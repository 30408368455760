import { DefaultOptionType } from 'antd/lib/select';

export const companyRolesOptions: DefaultOptionType[] = [
  { label: 'Full Stack Developer', value: 'full-stack-developer' },
  { label: 'Frontend Developer', value: 'frontend-developer' },
  { label: 'Mobile Developer', value: 'mobile-developer' },
  { label: 'Backend Developer', value: 'backend-developer' },
  { label: 'Product Manager', value: 'product-manager' },
  { label: 'QA Engineer', value: 'qa-engineer' },
  { label: 'DevOps Engineer', value: 'devops-engineer' },
  { label: 'Software Engineer', value: 'software-engineer' },
  { label: 'Data Engineer', value: 'data-engineer' },
  { label: 'UI/UX Designer', value: 'ui-ux-designer' },
  { label: 'Product Designer', value: 'product-designer' },
  { label: 'Team Lead', value: 'team-lead' },
  { label: 'Manager', value: 'manager' },
  { label: 'Executive', value: 'executive' },
  { label: 'Other', value: 'other' },
];
