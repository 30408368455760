import { Routes, Route, Navigate } from 'react-router-dom';

import { ProtectedOnboardRoute } from './ProtectedOnboardRoute';
import { ProtectedRoute } from './ProtectedRoute';
import { PublicRoute } from './PublicRoute';
import {
  HomePage,
  LoadingPage,
  LoginPage,
  OnboardingPage,
  SignupPage,
  PrivacyPage,
  TermsOfUsePage,
} from '../pages';
import { useAuth } from '../providers/AuthProvider';

export const MainRouter = () => {
  const { loading } = useAuth();

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <SignupPage />
          </PublicRoute>
        }
      />
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/onboarding"
        element={
          <ProtectedOnboardRoute>
            <OnboardingPage />
          </ProtectedOnboardRoute>
        }
      />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/terms" element={<TermsOfUsePage />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
};
