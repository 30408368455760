import { DefaultOptionType } from 'antd/lib/select';

export const roleOptions: DefaultOptionType[] = [
  { label: 'Full Stack Developer', value: 'full-stack-developer' },
  { label: 'Frontend Developer', value: 'frontend-developer' },
  { label: 'Mobile Developer', value: 'mobile-developer' },
  { label: 'Backend Developer', value: 'backend-developer' },
  { label: 'Product Manager', value: 'product-manager' },
  { label: 'QA Engineer', value: 'qa-engineer' },
  { label: 'DevOps Engineer', value: 'devops-engineer' },
  { label: 'Software Engineer', value: 'software-engineer' },
  { label: 'Data Engineer', value: 'data-engineer' },
  { label: 'UI/UX Designer', value: 'ui-ux-designer' },
  { label: 'Product Designer', value: 'product-designer' },
  { label: 'Team Lead', value: 'team-lead' },
  { label: 'Manager', value: 'manager' },
  { label: 'Executive', value: 'executive' },
  { label: 'Other', value: 'other' },
];

export const yearOfExperience: DefaultOptionType[] = [
  { label: 'Less than 1 year', value: 'less-than-1-year' },
  { label: '1-2 years', value: '1-2-years' },
  { label: '3-5 years', value: '3-5-years' },
  { label: '6-10 years', value: '6-10-years' },
  { label: '11-20 years', value: '11-20-years' },
  { label: 'More than 20 years', value: 'more-than-20-years' },
];
