import { DefaultOptionType } from 'antd/lib/select';

export const companySizeOptions: DefaultOptionType[] = [
  { label: '1-5 employees', value: '1-5' },
  { label: '6-20 employees', value: '6-20' },
  { label: '21-100 employees', value: '21-100' },
  { label: '101-500 employees', value: '101-500' },
  { label: '501-1000 employees', value: '501-1000' },
  { label: '1001+ employees', value: '1001+' },
];
