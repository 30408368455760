import { Image, Result, Typography } from 'antd';

import icon from '../../../assets/images/icon.svg';
import { useAuth } from '../../../providers/AuthProvider';

export const OnboardHeader = () => {
  const { userData } = useAuth();

  return (
    <Result
      style={{ padding: '32px 0px' }}
      icon={<Image width={50} src={icon} preview={false} />}
      title={
        <Typography.Title level={3} style={{ fontWeight: 'bold' }}>
          Welcome to EnzRossi Connect!
        </Typography.Title>
      }
      subTitle={
        <Typography.Text>
          {userData?.type === 'individual'
            ? 'Share a bit about yourself and your work'
            : 'Tell us about your company and projects'}
        </Typography.Text>
      }
    />
  );
};
