import * as Sentry from '@sentry/react';

import { appConfig } from '../config/appConfig';

export function initSentry() {
  if (!appConfig.sentryDsn) {
    return;
  }

  Sentry.init({
    dsn: appConfig.sentryDsn,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
