import './styles.css';
import { GoogleOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Image,
  Input,
  Segmented,
  Typography,
  message,
} from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import icon from '../../assets/images/icon.svg';
import { appConfig } from '../../config/appConfig';
import { auth, database, firebase } from '../../services/firebase';

export const SignupPage = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [type, setType] = useState('individual');
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: {
    type: string;
    name: string;
    email: string;
    password: string;
    terms: boolean;
  }) => {
    if (!values.terms) {
      messageApi.open({
        type: 'error',
        content: 'Please accept the terms and conditions',
      });
      return;
    }

    try {
      setLoading(true);
      const user = await auth.createUserWithEmailAndPassword(values.email, values.password);
      if (user.user?.uid) {
        await user.user.updateProfile({ displayName: values.name });
        await database.ref(`users/${user.user.uid}`).set({
          type: values.type,
          name: values.name,
          email: values.email,
          createdAt: new Date().toISOString(),
        });
      }
    } catch (error: any) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message,
      });
    }
  };

  const handleLoginGoogle = async () => {
    try {
      setLoading(true);
      const provider = new firebase.auth.GoogleAuthProvider();
      const { user } = await auth.signInWithPopup(provider);
      if (user?.uid) {
        await database.ref(`users/${user.uid}`).set({
          type,
          name: user.displayName,
          email: user.email,
          createdAt: new Date().toISOString(),
        });
      }
    } catch (error: any) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message,
      });
    }
  };

  return (
    <div className="signup">
      <div className="signup-form">
        <div className="signup-form-inner">
          {contextHolder}

          <Image width={40} src={icon} preview={false} />

          <Typography.Title style={{ marginBottom: 0, marginTop: 12, fontWeight: 'bold' }}>
            EnzRossi Connect
          </Typography.Title>
          <Typography.Text>Unlock Your Potential with EnzRossi! 🌟</Typography.Text>

          <Form
            style={{ marginTop: 32 }}
            requiredMark={false}
            initialValues={{ type: 'individual' }}
            layout="vertical"
            size="large"
            onFinish={onFinish}>
            <Form.Item
              style={{ marginBottom: 12 }}
              name="type"
              label="Join as"
              rules={[{ required: true, message: '' }]}>
              <Segmented
                block
                size="large"
                onChange={(e) => setType(e.toString())}
                options={[
                  { label: 'Individual', value: 'individual' },
                  { label: 'Company', value: 'company' },
                ]}
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 12 }}
              name="name"
              label={'Name'}
              rules={[{ required: true, message: '' }]}>
              <Input disabled={loading} prefix={<UserOutlined />} placeholder={'John Doe'} />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 12 }}
              name="email"
              label="Email"
              rules={[{ required: true, message: '' }]}>
              <Input disabled={loading} prefix={<MailOutlined />} placeholder="mail@example.com" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 12 }}
              label="Password"
              name="password"
              rules={[{ required: true, message: '' }]}>
              <Input
                disabled={loading}
                prefix={<LockOutlined />}
                type="password"
                placeholder="******"
              />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: 12 }}
              rules={[{ required: true, message: '' }]}
              name="terms"
              valuePropName="checked"
              initialValue={false}>
              <Checkbox>
                I have read and agree with the{' '}
                <a href={appConfig.termOfUseUrl} target="_blank" rel="noopener noreferrer">
                  Terms of Use
                </a>
                {' and '}
                <a href={appConfig.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </Checkbox>
            </Form.Item>

            <Form.Item style={{ marginBottom: 4 }}>
              <Button block loading={loading} type="primary" htmlType="submit">
                Sign up as {type === 'individual' ? 'an Individual' : 'a Company'}
              </Button>

              <Divider>or</Divider>

              <Button
                block
                icon={<GoogleOutlined />}
                loading={loading}
                type="default"
                onClick={handleLoginGoogle}>
                Sign up with Google
              </Button>
            </Form.Item>

            <Form.Item style={{ marginBottom: 12, textAlign: 'center' }}>
              <Typography.Text style={{ opacity: 0.8 }}>
                By signup you agree with our{' '}
                <a href={appConfig.termOfUseUrl} target="_blank" rel="noopener noreferrer">
                  Terms of Use
                </a>
                {' and '}
                <a href={appConfig.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </Typography.Text>
            </Form.Item>

            <Form.Item style={{ textAlign: 'center' }}>
              Already have an account?{' '}
              <Link to="/login">
                <strong>Sign in!</strong>
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="signup-extra-content">
        <div className="signup-extra-content-container">
          <Typography.Title level={4} style={{ color: '#fff' }}>
            Join EnzRossi Connect and elevate your tech career. Collaborate with top talent and
            drive innovation forward!
          </Typography.Title>
          <br />
          <Typography.Text italic style={{ color: '#fff' }}>
            Welcome to the future of remote tech teams.
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
