import { Col, Divider, Row, Tag, Typography } from 'antd';

import { AppLayout } from '../../components/AppLayout/AppLayout';
import { appConfig } from '../../config/appConfig';

export const HomePage = () => {
  return (
    <AppLayout>
      {/* <Breadcrumb
        items={[
          {
            title: <HomeTwoTone />,
          },
          {
            title: 'Home',
          },
        ]}
      /> */}

      <Row justify="center" style={{ marginTop: 24 }}>
        <Col xs={24} sm={24} md={24} lg={16}>
          <Tag color="red">Important notice</Tag>
          <Typography.Title style={{ fontWeight: 'bold' }} level={1}>
            Profile under review 🚀
          </Typography.Title>

          <Typography.Title level={3}>We're evaluating your profile</Typography.Title>
          <Typography.Text>
            Thank you for providing your details. Our team is currently evaluating your profile to
            ensure a perfect match.
            <br />
            This process helps us maintain the high standards we promise to our clients and team
            members.
          </Typography.Text>

          <Typography.Title level={3}>What to expect next</Typography.Title>
          <Typography.Text>
            We'll notify you via email and in-app notifications once your profile has been reviewed.
            <br />
            If your profile meets our criteria, we'll reach out to discuss the next steps, including
            potential projects and opportunities.
            <br />
            This process may take a few days. We appreciate your patience and understanding.
          </Typography.Text>

          <Divider />

          <Typography.Text>
            If you have any questions or require further information, feel free to reach out at
            <strong> {appConfig.contactEmail}</strong>. <br />
            Thank you for your interest in EnzRossi and your understanding during this evaluation
            process.
          </Typography.Text>
        </Col>
      </Row>
    </AppLayout>
  );
};
