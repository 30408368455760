import { CheckOutlined, DeleteOutlined, InboxOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Form, Space, Upload, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useState } from 'react';

import { useAuth } from '../../../providers/AuthProvider';
import { storage } from '../../../services/firebase';

interface SecondIndividualStepProps {
  onFinishStep: () => void;
  onGoBack: () => void;
}

export const SecondIndividualStep = ({ onFinishStep, onGoBack }: SecondIndividualStepProps) => {
  const { user, updateUserData } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<RcFile>();

  const handleSubmit = async () => {
    if (!file) {
      return;
    }

    try {
      setLoading(true);
      const snapshot = await storage.ref(`resumes/${user?.uid}/${file.name}`).put(file);
      const fileUrl = await snapshot.ref.getDownloadURL();
      await updateUserData({ resume: fileUrl });
      onFinishStep();
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message || 'Something went wrong, please try again.',
      });
    }
  };

  const handleUpload = async (file: RcFile) => {
    setFile(file);
  };

  return (
    <Form
      style={{ marginTop: 32 }}
      requiredMark={false}
      layout="vertical"
      size="large"
      onFinish={handleSubmit}>
      {contextHolder}

      <Form.Item style={{ marginBottom: 12 }} name="resume" label="Upload your CV">
        <Upload.Dragger
          accept=".pdf"
          beforeUpload={handleUpload}
          disabled={loading || !!file}
          listType="picture-card"
          maxCount={1}
          name="file"
          showUploadList={false}
          style={{ width: '100%' }}>
          {file ? (
            <>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{file.name}</p>
              <p className="ant-upload-hint">
                <Button size="small" icon={<DeleteOutlined />} onClick={() => setFile(undefined)}>
                  Remove
                </Button>
              </p>
            </>
          ) : (
            <>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag your CV to this area to upload</p>
              <p className="ant-upload-hint">
                Upload your CV (PDF format) to showcase your skills and experience.
              </p>
            </>
          )}
        </Upload.Dragger>
      </Form.Item>
      <div style={{ textAlign: 'center' }}>
        <Space>
          <Button type="default" onClick={onGoBack}>
            <LeftOutlined />
            Back
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            Done
            <CheckOutlined />
          </Button>
        </Space>
      </div>
    </Form>
  );
};
