export const firebaseConfig = {
  apiKey: 'AIzaSyDB4T_h-2qc5jzq55TTbjP34hkum-QsksY',
  authDomain: 'connect.enzrossi.com',
  databaseURL: 'https://enzrossi-1520702757332.firebaseio.com',
  projectId: 'enzrossi-1520702757332',
  storageBucket: 'enzrossi-1520702757332.appspot.com',
  messagingSenderId: '173837609471',
  appId: '1:173837609471:web:132583d107830b4534dfca',
  measurementId: 'G-EMC6GQX734',
};
