import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Space, message } from 'antd';
import { useState } from 'react';

import { roleOptions, yearOfExperience } from './options';
import { useAuth } from '../../../providers/AuthProvider';
import { UserData } from '../../../types/UserData';

interface FirstIndividualStepValues
  extends Pick<UserData, 'linkedin' | 'role' | 'yearOfExperience'> {}

interface FirstIndividualStepProps {
  onFinishStep: () => void;
}

export const FirstIndividualStep = ({ onFinishStep }: FirstIndividualStepProps) => {
  const { userData, updateUserData } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FirstIndividualStepValues) => {
    try {
      setLoading(true);
      await updateUserData(values);
      onFinishStep();
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message || 'Something went wrong, please try again.',
      });
    }
  };

  return (
    <Form
      style={{ marginTop: 32 }}
      requiredMark={false}
      layout="vertical"
      size="large"
      initialValues={{
        linkedin: userData?.linkedin,
        role: userData?.role,
        yearOfExperience: userData?.yearOfExperience,
      }}
      onFinish={handleSubmit}>
      {contextHolder}

      <Form.Item
        style={{ marginBottom: 12 }}
        name="linkedin"
        label="What is your LinkedIn profile?"
        rules={[{ required: true, message: '' }]}>
        <Input
          disabled={loading}
          addonBefore="https://www.linkedin.com/in/"
          placeholder="john-doe-12345"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 12 }}
        name="role"
        label="What is your primary role?"
        rules={[{ required: true, message: '' }]}>
        <Select
          allowClear
          disabled={loading}
          style={{ width: '100%' }}
          placeholder="Please select only one"
          options={roleOptions}
        />
      </Form.Item>
      <Form.Item
        name="yearOfExperience"
        label="How long have you been working in the tech industry?"
        rules={[{ required: true, message: '' }]}>
        <Select
          allowClear
          disabled={loading}
          style={{ width: '100%' }}
          placeholder="Please select only one"
          options={yearOfExperience}
        />
      </Form.Item>
      <div style={{ textAlign: 'center' }}>
        <Space>
          <Button type="default" disabled>
            <LeftOutlined />
            Back
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            Next
            <RightOutlined />
          </Button>
        </Space>
      </div>
    </Form>
  );
};
