import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/database';
import 'firebase/compat/storage';

import { firebaseConfig } from '../config/firebaseConfig';
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const database = firebase.database();
const analytics = firebase.analytics();
const storage = firebase.storage();

export { firebase, auth, database, analytics, storage };
