import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Space, message } from 'antd';
import { useState } from 'react';

import { companySizeOptions } from './options';
import { useAuth } from '../../../providers/AuthProvider';
import { UserData } from '../../../types/UserData';

interface FirstCompanyStepValues
  extends Pick<UserData, 'companyName' | 'companyWebsite' | 'companySize'> {}

interface FirstCompanyStepProps {
  onFinishStep: () => void;
}

export const FirstCompanyStep = ({ onFinishStep }: FirstCompanyStepProps) => {
  const { userData, updateUserData } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FirstCompanyStepValues) => {
    try {
      setLoading(true);
      await updateUserData(values);
      onFinishStep();
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message || 'Something went wrong, please try again.',
      });
    }
  };

  return (
    <Form
      style={{ marginTop: 32 }}
      requiredMark={false}
      layout="vertical"
      size="large"
      initialValues={{
        companyName: userData?.companyName,
        companyWebsite: userData?.companyWebsite,
        companySize: userData?.companySize,
      }}
      onFinish={handleSubmit}>
      {contextHolder}

      <Form.Item
        style={{ marginBottom: 12 }}
        name="companyName"
        label="What is your company name?"
        rules={[{ required: true, message: '' }]}>
        <Input disabled={loading} placeholder="EnzRossi" />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 12 }}
        name="companyWebsite"
        label="What is your company website?"
        rules={[{ required: true, message: '' }]}>
        <Input disabled={loading} addonBefore="https://" placeholder="enzrossi.com" />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 12 }}
        name="companySize"
        label="How many employees does your company have?"
        rules={[{ required: true, message: '' }]}>
        <Select
          allowClear
          disabled={loading}
          style={{ width: '100%' }}
          placeholder="Please select only one"
          options={companySizeOptions}
        />
      </Form.Item>
      <div style={{ textAlign: 'center' }}>
        <Space>
          <Button type="default" disabled>
            <LeftOutlined />
            Back
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            Next
            <RightOutlined />
          </Button>
        </Space>
      </div>
    </Form>
  );
};
