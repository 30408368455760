import './styles.css';
import { GoogleOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Image, Input, Space, Typography, message } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import icon from '../../assets/images/icon.svg';
import { auth, firebase } from '../../services/firebase';

export const LoginPage = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: { email: string; password: string; remember: boolean }) => {
    try {
      setLoading(true);
      await auth.signInWithEmailAndPassword(values.email, values.password);
    } catch (error: any) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message,
      });
    }
  };

  const handleLoginGoogle = async () => {
    try {
      setLoading(true);
      const provider = new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithPopup(provider);
    } catch (error: any) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message,
      });
    }
  };

  return (
    <div className="login">
      <div className="login-form">
        <div className="login-form-inner">
          {contextHolder}

          <Image width={40} src={icon} preview={false} />

          <Typography.Title style={{ marginBottom: 0, marginTop: 12, fontWeight: 'bold' }}>
            EnzRossi Connect
          </Typography.Title>
          <Typography.Text>Welcome back, please enter your details to continue</Typography.Text>

          <Form
            style={{ marginTop: 32 }}
            requiredMark={false}
            initialValues={{ remember: true }}
            layout="vertical"
            size="large"
            onFinish={onFinish}>
            <Form.Item
              style={{ marginBottom: 12 }}
              name="email"
              label="Email"
              rules={[{ required: true, message: '' }]}>
              <Input disabled={loading} prefix={<MailOutlined />} placeholder="mail@example.com" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 12 }}
              label="Password"
              name="password"
              rules={[{ required: true, message: '' }]}>
              <Input
                disabled={loading}
                prefix={<LockOutlined />}
                type="password"
                placeholder="******"
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 12 }}>
              <Space
                align="center"
                direction="horizontal"
                style={{ justifyContent: 'space-between', width: '100%' }}>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item noStyle>
                  {/* TODO */}
                  {/* <Button size="middle" type="text" onClick={() => auth.sendPasswordResetEmail('')}>
                    Forgot password
                  </Button> */}
                </Form.Item>
              </Space>
            </Form.Item>

            <Form.Item>
              <Button block loading={loading} type="primary" htmlType="submit">
                Log in
              </Button>

              <Divider>or</Divider>

              <Button block loading={loading} type="default" onClick={handleLoginGoogle}>
                <GoogleOutlined />
                Sign in with Google
              </Button>
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
              Don't have an account?{' '}
              <Link to="/signup">
                <strong>Sign up!</strong>
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="login-extra-content">
        <div className="login-extra-content-container">
          <Typography.Title level={4}>
            Ready to innovate and collaborate? Let's make tech magic happen.
          </Typography.Title>
          <br />
          <Typography.Text italic>Dive back into your projects and lead the way.</Typography.Text>
        </div>
      </div>
    </div>
  );
};
